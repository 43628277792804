
import {Component, Vue, Watch} from "vue-property-decorator";

import ChargePointForm from '@/components/ChargePointForm.vue';
import RouteDialog from "@/components/RouteDialog.vue";
import {
  BasicAuthStatus,
  CentralSystem,
  ChargePoint,
  LinkedCentralSystem,
  NetworkProfile,
  RouteDialogConfirmEvent
} from "@/domain";
import {namespace} from "vuex-class";
import {BasicAuthDeviation} from "@/domain/basic-auth-deviation";

const centralSystemsModule = namespace('centralSystems');
const basicAuthDeviationsModule = namespace('basicAuthDeviations');
const networkProfilesModule = namespace('networkProfiles');

@Component({
  components: {RouteDialog, ChargePointForm},
  data: () => ({
    config: undefined,
  })
})
export default class NewChargePointDialog extends Vue {
  @centralSystemsModule.State('items')
  readonly availableCentralSystems!: CentralSystem[];

  @basicAuthDeviationsModule.State('items')
  readonly basicAuthDeviations!: BasicAuthDeviation[];

  @networkProfilesModule.State('items')
  readonly networkProfiles!: NetworkProfile[];

  loading: boolean = true;

  incorrectAmountOfMasterCentralSystems: boolean = false;

  chargePoint: Partial<ChargePoint & { changeBasicAuthPassword: boolean }> = {
    name: '',
    ocppVersion: '1.6',
    type: 'WEBSOCKET',
    useManualURI: false,
    useRequestHeader: false,
    omitSoapHeader: false,
    basicAuthStatus: BasicAuthStatus.NOT_REQUIRED,
    changeBasicAuthPassword: false,
    basicAuthPassword: undefined,
    basicAuthDeviationId: -1,
    networkProfileId: 1,
  };

  selectedCentralSystems: LinkedCentralSystem[] = [];

  private unsubscribe!: () => void;

  @Watch('selectedCentralSystems', {deep: true})
  updateSelectedCentralSystems(newCentralSystems: LinkedCentralSystem[]) {
    console.log(newCentralSystems.filter(cs => cs.type === 'MASTER').length);
    if (newCentralSystems.filter(cs => cs.type === 'MASTER').length == 1) {
      this.incorrectAmountOfMasterCentralSystems = false;
    }
  }

  async onConfirm(event: RouteDialogConfirmEvent) {
    if (this.selectedCentralSystems.filter(cs => cs.type === 'MASTER' && cs.selected == true).length != 1) {
      this.incorrectAmountOfMasterCentralSystems = true;
      event.cancel();
      return;
    }
    try {
      /* Exactly one central system is selected as MASTER */
      this.incorrectAmountOfMasterCentralSystems = false;

      const payload = {
        ...this.chargePoint,
        basicAuthPassword: this.chargePoint.changeBasicAuthPassword ? this.chargePoint.basicAuthPassword : undefined,
        basicAuthDeviationId: this.chargePoint.basicAuthDeviationId === -1 ? null : this.chargePoint.basicAuthDeviationId,
      } as ChargePoint;
      await this.$store.dispatch("chargePoints/createOne", payload);

      this.selectedCentralSystems.forEach((cs, index) => {
        this.$store.dispatch("specific/linkCentralStation", {
          id: this.chargePoint.id,
          centralStationName: cs.name,
          link: cs.selected,
          type: cs.type,
          customChargePointName: cs.customChargePointName,
          commandsAllowed: cs.commandsAllowed,
          sessionAuthorizationAllowed: cs.sessionAuthorizationAllowed,
          forwardBasicAuth: cs.forwardBasicAuth,
        });
      });
      event.done();
    } catch (e) {
      console.log("An error was occured whilst creating: ", e);
      event.cancel();
    }
  }

  async mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "chargePoints/UPDATE_ITEM") {
        this.chargePoint.id = mutation.payload.id
      }
    });
    await this.$store.dispatch("centralSystems/fetchAll");
    await this.$store.dispatch("basicAuthDeviations/fetchAll");
    await this.$store.dispatch("networkProfiles/fetchAll");
    await this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
    this.selectedCentralSystems = this.availableCentralSystems.map(cs => ({
      id: cs.id,
      name: cs.name,
      type: 'READONLY',
      selected: false,
      customChargePointName: undefined,
      commandsAllowed: true,
      sessionAuthorizationAllowed: false,
      forwardBasicAuth: this.$data.config.forwardBasicAuthDefaultValue,
    }));
    this.loading = false;
  }

  async beforeDestroy() {
    this.unsubscribe();
  }
}
